import React, { useEffect, useState } from "react";
import HeaderBar from "../../components/headerBar";
import "./index.css";
import { getRecordList } from "../../api";

enum QrCodeStatus {
  WAITING = "WAITING",
  GENERATING = "GENERATING",
  SUCCESS = "SUCCESS",
  FAIL = "FAIL",
}

const History = () => {
  const [recordList, setRecordList] = useState<any[]>([]);
  const [currentList, setCurrentList] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [imgUrl, setImgUrl] = useState<string>("");

  useEffect(() => {
    getRecordList({}).then((res) => {
      setRecordList(
        res.data.filter((item: any) => {
          return (
            item.status === QrCodeStatus.SUCCESS ||
            item.status === QrCodeStatus.FAIL
          );
        }) || []
      );
      setCurrentList(
        res.data.filter((item: any) => {
          return (
            item.status === QrCodeStatus.WAITING ||
            item.status === QrCodeStatus.GENERATING
          );
        }) || []
      );
    });
  }, []);

  const handleOpenImg = (img: string) => {
    setOpen(true);
    setImgUrl(img);
  };

  return (
    <div className="px-2 relative bg-gray-900 min-h-screen overflow-hidden flex flex-col items-center opacity-0 animate-show">
      <HeaderBar />

      <div className="task-schedule">
        <div className="task-schedule-name">
          {currentList.length || 0}个任务队列中
        </div>
        <div className="task-schedule-desc">
          预计还需{currentList[0]?.currentWaitPosition || 0}
          分钟，稍后在本页查看
        </div>
      </div>

      <div className="history-desc">历史记录</div>
      <div className="img-list">
        {recordList.map((item) => {
          return (
            <img
              className="img"
              src={
                JSON.parse(item.responseContent).imageUrl ||
                "https://simpletool-1254123789.cos.ap-chengdu.myqcloud.com/houseMap/upload/1/1698393433708WeChata64187ed324e2f9472e230f5ec1d2315.png"
              }
              onClick={() => {
                handleOpenImg(
                  JSON.parse(item.responseContent).imageUrl ||
                    "https://simpletool-1254123789.cos.ap-chengdu.myqcloud.com/houseMap/upload/1/1698393433708WeChata64187ed324e2f9472e230f5ec1d2315.png"
                );
              }}
            />
          );
        })}
      </div>

      {open && (
        <div className="history-item">
          <div className="history-item-title">长按保存二维码</div>
          <div className="history-item-img">
            <img src={imgUrl} />
          </div>
          <div
            className="history-item-close"
            onClick={() => {
              setOpen(false);
              setImgUrl("");
            }}
          >
            关闭
          </div>
        </div>
      )}
    </div>
  );
};

export default History;
